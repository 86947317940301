import React from "react";

import AppRouter from "./ROUTER/Approuter";

function App() {

  return (
    <>
      <AppRouter/>
    </>
  );
}

export default App;
