import React from "react";

import FormComponent from "../mock/FormMiHCMComponent.jsx";

function MiHCMComponent() {
  
  return (
    <>
       <FormComponent/>
    </>
  );
}

export default MiHCMComponent;