import React from "react";
import MiHCMComponent from "../components/MiHCMComponent";

function MIHCM() {
  
  return (
    <>
       <MiHCMComponent/>
    </>
  );
}

export default MIHCM;